<template>
  <v-container>
    
    pdf:
    <div>
      <form>
        <input type="file" ref="pdfFile" @change="onFileChange">
      </form>
      <pre v-if="!loading" v-html="extractedText"></pre>
      <div v-if="loading">Processing PDF...</div>
    </div>

    prompt:
    <div>
      <v-text-field v-model="question" outlined></v-text-field>
    </div>

    cnt:
    <div>
      <v-text-field v-model="cnt" outlined></v-text-field>
    </div>

    key:
    <div>
      <v-text-field v-model="key" outlined></v-text-field>
    </div>

    
    <div>
      <v-btn @click="handleInterface">Check</v-btn>
    </div>
    
    result:
    <div v-html="resultText" class="px-5 py-5" style="white-space: pre-line;"></div>


    <div>
      <v-btn @click="handleProductLog">Log</v-btn>
    </div>
    log:
    <div v-html="logText" class="px-5 py-5" style="white-space: pre-line;"></div>

  </v-container>
</template>
<script>
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry.js';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
export default {
  name: 'InterfaceView',
  data: () => ({
    question:'Who are you',
    key:'',
    cnt:1,
    resultText: 'Waiting...',
    logText:'',
    extractedText: '',
    loading: false // add loading property
  }),
  methods: {

    async extractTextFromPdf(document) {
      this.loading = true;
      const pdf = await pdfjsLib.getDocument(document).promise;
      const maxPages = pdf.numPages;
      let textContent = [];

      for (let i = 1; i <= maxPages; i++) {
        const page = await pdf.getPage(i);
        const content = await page.getTextContent();
        console.log(content)
        const pageTextContent = content.items.map(item => item.str).join('');
        textContent.push(pageTextContent);
      }
      this.loading = false;

      return textContent.join('\n');
    },

    async onFileChange(event) {
      const file = event.target.files[0];
      const extension = file.name.split('.').pop();

      if (extension !== 'pdf') {
        alert('Please select a PDF file');
        return;
      }

      const reader = new FileReader();

      reader.onload = async () => {
        const dataUrl = reader.result;

        // set loading to true before extracting text from the PDF
        this.loading = true;

        const text = await this.extractTextFromPdf(dataUrl);
        this.extractedText = text;

        // set loading to false when the text extraction is complete
        this.loading = false;
      };

      reader.readAsDataURL(file);
    },
    async handleInterface() {
      let question = this.question;
      let key = this.key;
      this.resultText='';

      let chatList = []
      chatList.push({
        "role": "user",
        "content": question
      })
      
      let apiUrl='https://api.huashikeji.com/requestInterface'
      // let apiUrl='http://127.0.0.1:8787/requestInterface'
      let handlerKey=[
      
      'llmhandlepdfai_0',

      'llmhandleBaseD',
      'llmhandleBaseXY',
      'llmhandleBaseJJ',
      'llmhandleBaseGL',
      'llmhandleBaseJT',
      'llmhandleBaseQWen',


      'llmhandleProJJ',
      'llmhandleProGL',
      'llmhandleProJT',
      'llmhandleProQWen',
      ]
      // let handlerCnt=handlerKey.length
      let handlerCnt=this.cnt
      for (let i = 0; i < handlerCnt; i++) {
        try {
          this.resultText = this.resultText + '\n' + `==${handlerKey[i]}==` + '\n'
          let request_json = {
            'did': 'internal',
            'chatList': chatList,
            'special':{
              
            }
          };
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'handler':'test'+i,
                'key':key
            },
            body: JSON.stringify(request_json)
          });
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8")
          const condition = true
          while (condition) {
            const { value, done } = await reader.read();
            if (done) {
              break;
            }
            let decodedValue = decodeURIComponent(decoder.decode(value));
            this.resultText += decodedValue;
          }
        } catch {
          this.resultText += 'error';
          continue
        }
      }
    },

    async handleProductLog(){
      let apiUrl='https://api.huashikeji.com/productlogs'
      const response = await fetch(apiUrl, {
        method: 'POST'
      });
      const logText=await response.text()
      console.log(logText)
      this.logText=logText
    }
  }

};

</script>