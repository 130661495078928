<template>
  <v-app>
    <v-app-bar app elevation="1">
      <div class="d-flex align-center">
        <router-link to="/" class='text-decoration-none' >
          <v-img alt="PowerChat Logo" class="shrink mr-2" contain src="@/assets/H2.png" transition="scale-transition" width="60" />
        </router-link>
        <span class="text-h6 font-weight-black">HUA SHI</span>
      </div>
      <v-spacer></v-spacer>
      <div>
        <router-link to='/' class='text-decoration-none black--text'>
          <v-btn text>
            <span class="mr-2 text-capitalize font-weight-black">Home</span>
          </v-btn>
        </router-link>
        <router-link to='/contact' class='text-decoration-none black--text'>
          <v-btn text>
            <span class="mr-2 text-capitalize font-weight-black">Contact</span>
          </v-btn>
        </router-link>
<!--         <router-link to='/interface' class='text-decoration-none black--text'>
          <v-btn text>
            <span class="mr-2 text-capitalize text-h6">Interface</span>
          </v-btn>
        </router-link> -->
      </div>      
    </v-app-bar>
    <v-main>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name:'AppView'
}
</script>

