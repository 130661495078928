import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ContactView from '../views/ContactView.vue';
import InterfaceView from '../views/InterfaceView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta:{
      'title':'Home - HUA SHI INTERNATIONAL LIMITED'
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactView,
    meta:{
      'title':'Contact - HUA SHI INTERNATIONAL LIMITED'
    }
  },
  {
    path: '/interface',
    name: 'interface',
    component: InterfaceView,
    meta:{
      'title':'Interface - HUA SHI INTERNATIONAL LIMITED'
    }
  },
  {
    path:"*",
    redirect:'/'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
  next()
});

export default router;
