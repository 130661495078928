<template>
  <div>
    <div class="mt-16">
      <p class="blue-grey--text text--darken-4 text-center text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black">Welcome Here</p>
    </div>

    <div class="pt-2 pb-2">
      <div>
        <v-container class="my-10 py-5" >
          <v-row class="justify-center" align="stretch">
            <v-col cols="10" sm="10" md="5" lg="5" xl="5">
              <v-card elevation="2" rounded="xl" outlined>
                <v-img src="@/assets/pic_about.jpg" rounded contain></v-img>
              </v-card>
            
            </v-col>
            <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="d-flex align-center flex-wrap">
              <div>
                <p class="text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black">Hi there, We are Hua Shi International Limited 🍻</p>
                <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
                  Our team consists of passionate technology enthusiasts committed to delivering the finest natural language processing service. For any inquiries or suggestions, please don't hesitate to reach out. We're here to help. Thank you for exploring our website!
                </p>
                <v-btn color="indigo" x-large class="mt-6" rounded @click="handleContact">
                  <v-icon right color="white" class="mx-auto mr-4">
                    mdi-human-greeting-variant
                  </v-icon>
                  <h6 class="text-capitalize white--text text-body-1 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6 font-weight-black">              
                    Contact Us
                  </h6>
                </v-btn>
              </div>
            </v-col>

          </v-row>
        </v-container>
      </div>
    </div>

    <div style="background-color: rgb(250,250,252);" class="pt-2 pb-2">
      <div>
        <v-container class="my-10 py-5" >
          <v-row class="justify-center" align="stretch">
            <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="d-flex align-center flex-wrap">
              <div>
                <p class="text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h3 font-weight-black">Our vision 😉</p>
                <p class="mt-5 text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 font-weight-black">
                  We leverage the strength of artificial intelligence to enrich human life. By employing cutting-edge technology, we craft transformative solutions that redefine the future. Our portfolio of AI products, ranging from intuitive chatbots to sophisticated systems, addresses today's most pressing challenges. Guided by our vision, we strive to illuminate a brighter future for all, thereby unlocking the untapped potential of tomorrow.
                </p>
              </div>
            </v-col>
            <v-col cols="10" sm="10" md="5" lg="5" xl="5">
              <v-card elevation="2" rounded="xl" outlined>
                <v-img src="@/assets/pic_about2.jpg" rounded contain></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <v-footer padless>
      <v-card-text flat tile class="grey darken-4 lighten-1 text-center white--text" width="100%">
        Copyright ©{{ new Date().getFullYear() }} —
        <router-link to="/" class='text-decoration-none white--text'>
          <strong>https://www.huashikeji.com</strong>
        </router-link>
      </v-card-text>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: 'HomeView',
  methods: {
    handleContact() {
      if (this.$route.path !== '/contact') {
        this.$router.push({ path: '/contact'});
      }
    },
  }
};

</script>